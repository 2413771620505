@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-bg {
  background-color: #FFEB3B; /* 黄色 */
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%);
  z-index: -999;
}

.bg-green-500 {
  background-color: #38A169;
}

.clip-trapezoid {
  clip-path: polygon(5% 0, 100% 0, 100% 90%, 20% 95%);
  overflow: hidden;
  z-index: -1;
}

.clip-trapezoid-sp {
  clip-path: polygon(0% 0, 100% 0, 100% 92%, 0% 100%);
  overflow: hidden;
  z-index: -1;
}
